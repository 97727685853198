function gtag() {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

const injectIframe = (id) => {
  const f = document.getElementsByTagName('body')[0];
  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://tag-manager.prod.tf-b2c.com/ns.html?id=${id}`;
  iframe.setAttribute('width', '0');
  iframe.setAttribute('height', '0');
  iframe.setAttribute('style', 'display:none; visibility:hidden');
  noScript.appendChild(iframe);
  f.appendChild(noScript);
};

export default function gtmInit() {
  if (!process.env.GTM_ID || !process.env.GA_AUTH || !process.env.GA_ENV_ID) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  ((w, d, s, l, i) => {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://tag-manager.prod.tf-b2c.com/gtm.js?id=${i}${dl}&gtm_auth=${process.env.GA_AUTH}&gtm_preview=env-${process.env.GA_ENV_ID}&gtm_cookies_win=x`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', process.env.GTM_ID);

  injectIframe(process.env.GTM_ID);

  gtag('consent', 'default', {
    ad_storage: 'granted',
    analytics_storage: 'granted'
  });
}
