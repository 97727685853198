/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import {
  call,
  put,
  take,
  takeEvery,
  takeLeading,
  select,
  spawn,
  delay,
  race
} from 'redux-saga/effects';

import authApi from '../api/auth';
import signApi from '../api/sign';
import creditAccountApi from '../api/creditAccount';
import UnexpectedServerResponse from '../api/UnexpectedServerResponse';
import {
  AUTHORIZE,
  PREPARE,
  SET_PURCHASE_REQUEST_TOKEN,
  SET_PARENT_WINDOW_ORIGIN,
  START,
  SET_SELECTED_PAYMENT_METHOD,
  SHOW_CANCEL_VIEW,
  START_AUTH,
  AUTHENTICATION_DONE,
  CANCEL_AUTHENTICATION,
  OPEN_WINDOW,
  WEBCHECKOUT_EVENT,
  CANCEL_PAYMENT,
  AUTHENTICATION_ALREADY_IN_PROGRESS,
  START_SIGN,
  CANCEL_SIGNING,
  SIGNING_DONE,
  SIGNING_ALREADY_IN_PROGRESS,
  SIGNING_FAILED,
  USER_INFO_COLLECTION_CONTINUE_CLICKED,
  USER_INFO_COLLECTION_CANCELLED_CLICKED,
  MONTHIO_FLOW_COMPLETED_DONE,
  MONTHIO_CANCEL,
  MONTHIO_POLL_ONCE,
  MONTHIO_CREATE_DONE,
  MONTHIO_OPEN_AGAIN,
  CANCEL_SBID_AUTH_OR_SIGN,
  QUESTIONS_POST,
  QUESTIONS_CHECK,
  CONFIG_RETRY
} from '../constants/actions';
import {
  configComplete,
  authenticationStarted,
  authenticationCanceled,
  authenticationAlreadyInProgress,
  authenticationFailed,
  authenticationDone,
  authorizeStarted,
  authorizeFailed,
  authorizeComplete,
  prepareFailed,
  setAuthRequired,
  setRedirectUrl,
  signingStarted,
  signingCanceled,
  signingDone,
  signingFailed,
  signingTechnicalError,
  signingAlreadyInProgress,
  userInfoCollectionComplete,
  userInfoCollectionEApiError,
  authorizeIsUnauthorized,
  monthioFlowCompletedDone,
  monthioFlowCompletedError,
  monthioPollOnceDone,
  monthioCreateDone,
  monthioCreateError,
  setIsMonthioFlow,
  monthioPollError,
  authenticationSignQR,
  authenticationSignAutoStartToken,
  kycFlowToggle,
  setAuthToken,
  setKycQuestions,
  setKycVersion,
  accountLookupComplete,
  riskAssessmentComplete,
  riskAssessmentFailed,
  accountLookupStarted,
  riskAssessmentStarted,
  technicalError,
  kalpFlowToggle,
  questionsViewToggle,
  setKalpQuestions,
  setKalpVersion,
  setQuestionsToDisplay,
  setIsLoading,
  configHighLoad,
  configCircuitBreaker,
  setLanguage
} from '../actions/applicationActions';
import {
  authenticationEvent,
  chosenPaymentMethodEvent,
  paymentProcessingEvent,
  webcheckoutInitiatedEvent,
  webcheckoutCompleteCancelEvent,
  webcheckoutCompleteErrorEvent,
  EVENT_VALUE,
  signingEvent,
  emailAndPhoneRedirectClicked
} from '../actions/eventActions';
import { selectPaymentDetailsPartPayment } from '../selectors/selectors';
import postMessageOut from '../helpers/postMessage/post-message-out';
import { addCustomCssToDocument } from '../helpers/addCustomCss';
import {
  trackCancel,
  trackCreditCheckResult,
  trackStart,
  trackClickedConfirmPurchase,
  trackSign,
  trackKycComplete,
  trackKalpComplete,
  trackGenesisInitiate,
  trackAccountLookup,
  trackAuthenticateComplete,
  trackContactInfo,
  trackPurchaseComplete
} from '../helpers/analytics';
import monthioCreate from '../api/monthio/createCase';
import monthioPoll, { monthioPollStatus } from '../api/monthio/poll';
import sbidStart from '../api/auth/sbidStart';
import sbidPoll, { sbidPollStatus } from '../api/auth/sbidPoll';
import sbidCancel from '../api/auth/sbidCancel';
import sbidSignStart from '../api/sign/sbidSignStart';
import sbidSignPoll from '../api/sign/sbidSignPoll';
import sbidSignCancel from '../api/sign/sbidSignCancel';
import riskAssessment from '../api/credit-account/riskAssessment';
import accountLookup from '../api/credit-account/accountLookup';
import accountLookupStatusType from '../constants/accountLookupStatusType';
import creditAccountConfig, {
  configPollStatus
} from '../api/credit-account/config';

function* pollConfig(pollDelay = 3000) {
  const { correlationId, purchaseRequestToken } = yield select(
    (state) => state.app
  );

  yield put(configHighLoad());
  while (true) {
    const response = yield call(
      creditAccountConfig,
      purchaseRequestToken,
      correlationId
    );

    if (
      response.status !== configPollStatus.OK &&
      response.status !== configPollStatus.HIGH_LOAD &&
      response.status !== configPollStatus.CIRCUIT_BREAKER
    ) {
      return response;
    }
    if (response.status === configPollStatus.OK) {
      return response;
    }
    if (response.status === configPollStatus.CIRCUIT_BREAKER) {
      const {
        circuitBreakerWaitTimeInSeconds,
        merchantConfig: { displayLanguage }
      } = response;
      yield put(configCircuitBreaker(circuitBreakerWaitTimeInSeconds * 1000));
      yield put(setLanguage(displayLanguage));

      return response;
    }

    const {
      trafficFlattenerWaitTimeInSeconds,
      merchantConfig: { displayLanguage }
    } = response;

    yield put(configHighLoad(trafficFlattenerWaitTimeInSeconds));

    yield put(setLanguage(displayLanguage));

    yield delay(pollDelay);
  }
}

function* initiatePurchaseRequest({ purchaseRequestToken }) {
  try {
    yield put({
      type: SET_PURCHASE_REQUEST_TOKEN,
      purchaseRequestToken
    });

    const response = yield call(pollConfig, 3000);

    const {
      customer: { authRequired, ssn, email, mobile },
      merchantConfig: {
        displayLanguage,
        country,
        currency,
        storeName,
        strongSignRequired,
        emailPhoneCollectionEnabled,
        cardPaymentEnabled,
        merchantId,
        flow,
        monthsSelection,
        billingFeePaper: paperInvoiceFee,
        partPaymentMinimumAmount,
        kalpEnabled,
        kycEnabled,
        uiConfig
      },
      order
    } = response;

    const language = displayLanguage;

    const config = {
      locale: { country, language, currency },
      paymentOptions: {
        availableMonths: monthsSelection.available,
        defaultMonths: monthsSelection.default
      },
      lockedMonth: monthsSelection.locked,
      partPaymentMinimumAmount,
      flow,
      order: { items: order.orderItems, paperInvoiceFee },
      storeName,
      strongSignRequired,
      emailPhoneCollectionEnabled,
      ssn,
      email,
      mobile,
      merchantId,
      cardPaymentEnabled,
      kalpEnabled,
      kycEnabled,
      uiConfig
    };

    if (authRequired) {
      yield put(setAuthRequired(ssn));
    }

    const { preSelectedMonth } = yield select((state) => state.app);

    yield put(configComplete(config, preSelectedMonth));
    // Keep this for now to be sure to trigger the associated WEBCHECKOUT_EVENT.
    // Also, having this here makes the graphics render instantly on
    // Firefox (78.15.0esr). When this action is removed, there is often a long
    // delay before rendering, almost up to a second.
    if (!cardPaymentEnabled) {
      yield put({
        type: SET_SELECTED_PAYMENT_METHOD,
        paymentMethod: 'account'
      });
    }

    yield put(
      webcheckoutInitiatedEvent({
        order,
        currency,
        storeName,
        language
      })
    );

    yield call(trackStart);
    yield call(trackGenesisInitiate);
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
    } else {
      console.error(e);
    }
    yield put(
      webcheckoutCompleteErrorEvent(`initiatePurchaseRequest: ${e.toString()}`)
    );
  }
}

function* kycKalpQuestions() {
  const { purchaseRequestToken, correlationId, kycFlowDone, kalpFlowDone } =
    yield select((state) => state.app);
  try {
    if (!kycFlowDone) {
      yield put(setIsLoading(true));
      const result = yield call(
        creditAccountApi.getKycQuestions,
        purchaseRequestToken,
        correlationId
      );
      yield put(setIsLoading(false));

      yield put(setKycQuestions(result.questions));
      yield put(setKycVersion(result.version));
    }
    if (!kalpFlowDone) {
      yield put(setIsLoading(true));
      const result = yield call(
        creditAccountApi.getKalpQuestions,
        purchaseRequestToken,
        correlationId
      );
      yield put(setIsLoading(false));

      yield put(setKalpQuestions(result.questions));
      yield put(setKalpVersion(result.version));
    }
    const { kycQuestions, kalpQuestions } = yield select((state) => state.app);

    if (!kycFlowDone && kalpFlowDone) {
      yield put(setQuestionsToDisplay(kycQuestions));
    }

    if (kycFlowDone && !kalpFlowDone) {
      yield put(setQuestionsToDisplay(kalpQuestions));
    }

    if (!kycFlowDone && !kalpFlowDone) {
      yield put(
        setQuestionsToDisplay(Object.assign(kycQuestions, kalpQuestions))
      );
    }

    yield put(questionsViewToggle(true));
  } catch (e) {
    yield put(setIsLoading(false));
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
      yield put(
        webcheckoutCompleteErrorEvent(`KYC/KALP get questions: ${e.toString()}`)
      );
      yield call(cancelPayment);
    } else {
      console.error(e);
      yield put(
        webcheckoutCompleteErrorEvent(`KYC/KALP get questions: ${e.toString()}`)
      );
      yield call(cancelPayment);
    }
  }
}

function* checkKycKalpEnabled() {
  const { kycEnabled, kalpEnabled, country } = yield select(
    (state) => state.app
  );
  if (kycEnabled || (kalpEnabled && country !== 'DK')) {
    return yield call(kycKalpCheck);
  }
  return yield call(shouldMonthioBeUsed);
}

function* kycKalpCheck() {
  yield call(accountLookupCaller);

  const {
    purchaseRequestToken,
    correlationId,
    kycEnabled,
    kalpEnabled,
    country
  } = yield select((state) => state.app);
  const { accountLookupStatus } = yield select((state) => state.partPayment);

  if (
    accountLookupStatus === accountLookupStatusType.ORDER_AMOUNT_TOO_LOW ||
    accountLookupStatus === accountLookupStatusType.ACCOUNT_BLOCKED ||
    accountLookupStatus === accountLookupStatusType.GENERIC_DECLINE ||
    accountLookupStatus === accountLookupStatusType.CREDIT_CHECK_REJECTED
  ) {
    return;
  }

  try {
    if (!kycEnabled) {
      yield put(kycFlowToggle(true));
    }
    if (!kalpEnabled || country === 'DK') {
      yield put(kalpFlowToggle(true));
    }

    if (kycEnabled) {
      yield put(setIsLoading(true));
      try {
        const result = yield call(
          creditAccountApi.kycCheck,
          purchaseRequestToken,
          correlationId
        );
        yield put(setIsLoading(false));
        switch (result.status) {
          case 'FOUND':
            yield put(questionsViewToggle(false));
            yield put(kycFlowToggle(true));
            break;
          case 'NOT FOUND':
            yield put(kycFlowToggle(false));
            break;
          default:
            yield put(setIsLoading(false));
            throw new Error(
              `BUG: kyc check needs to return either OK or NOT FOUND as status. Got "${result.status}"`
            );
        }
      } catch (e) {
        yield put(setIsLoading(false));
        yield put(kycFlowToggle(true));
        if (e instanceof UnexpectedServerResponse) {
          console.error(e, e.response);
          yield put(
            webcheckoutCompleteErrorEvent(`KYC check: ${e.toString()}`)
          );
          yield call(cancelPayment);
        } else {
          console.error(e);
          yield put(
            webcheckoutCompleteErrorEvent(`KYC check: ${e.toString()}`)
          );
          yield call(cancelPayment);
        }
      }
    }

    if (kalpEnabled) {
      try {
        yield put(setIsLoading(true));
        const result = yield call(
          creditAccountApi.kalpCheck,
          purchaseRequestToken,
          correlationId,
          country
        );
        yield put(setIsLoading(false));
        switch (result.status) {
          case 'OK':
            yield put(questionsViewToggle(false));
            yield put(kalpFlowToggle(true));
            break;
          case 'NOT FOUND':
            yield put(kalpFlowToggle(false));
            break;
          default:
            yield put(setIsLoading(false));
            throw new Error(
              `BUG: kalp check needs to return either OK or NOT FOUND as status. Got "${result.status}"`
            );
        }
      } catch (e) {
        yield put(setIsLoading(false));
        yield put(kalpFlowToggle(true));
        if (e instanceof UnexpectedServerResponse) {
          console.error(e, e.response);
          yield put(
            webcheckoutCompleteErrorEvent(`KALP check: ${e.toString()}`)
          );
          yield call(cancelPayment);
        } else {
          console.error(e);
          yield put(
            webcheckoutCompleteErrorEvent(`KALP check: ${e.toString()}`)
          );
          yield call(cancelPayment);
        }
      }
    }
    const { kycFlowDone, kalpFlowDone } = yield select((state) => state.app);

    if (kycFlowDone && country === 'DK') {
      yield call(shouldMonthioBeUsed);
    }
    if (kycFlowDone && kalpFlowDone && country !== 'DK') {
      yield call(riskAssessmentCaller);
    }
    if (!kycFlowDone || !kalpFlowDone) {
      yield call(kycKalpQuestions);
    }
  } catch (e) {
    yield put(setIsLoading(false));
    yield put(prepareFailed());
  }
}

function* postKycKalpQuestions({ values } = {}) {
  const {
    purchaseRequestToken,
    correlationId,
    language,
    country,
    kycVersion,
    kalpVersion,
    kycEnabled,
    kycFlowDone,
    kalpFlowDone,
    kalpEnabled
  } = yield select((state) => state.app);

  const useKyc = kycEnabled && !kycFlowDone;
  const useKalp = kalpEnabled && !kalpFlowDone;

  try {
    if (useKyc && useKalp) {
      const kycValues = {};
      const kalpValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (key.includes('kyc')) {
          Object.assign(kycValues, { [key]: value });
        }
        if (key.includes('contact')) {
          Object.assign(kycValues, { [key]: value });
        }
        if (key.includes('kalp')) {
          Object.assign(kalpValues, { [key]: value });
        }
      });
      yield put(setIsLoading(true));
      const kycResult = yield call(
        creditAccountApi.kycCreate,
        purchaseRequestToken,
        correlationId,
        language,
        kycVersion,
        kycValues
      );
      const kalpResult = yield call(
        creditAccountApi.kalpCreate,
        purchaseRequestToken,
        correlationId,
        country,
        kalpVersion,
        kalpValues
      );
      yield put(setIsLoading(false));
      if (kycResult.status === 'OK' && kalpResult.status === 'OK') {
        yield put(kalpFlowToggle(true));
        yield put(kycFlowToggle(true));
        yield call(trackContactInfo);
        yield call(trackKycComplete);
        yield call(trackKalpComplete);
        yield put(questionsViewToggle(false));
        yield call(riskAssessmentCaller);
      } else {
        yield call(cancelPayment);
        yield put(questionsViewToggle(false));
      }
    }
    if (useKyc && !useKalp) {
      yield put(setIsLoading(true));
      const result = yield call(
        creditAccountApi.kycCreate,
        purchaseRequestToken,
        correlationId,
        language,
        kycVersion,
        values
      );
      yield put(setIsLoading(false));
      switch (result.status) {
        case 'OK':
          yield put(questionsViewToggle(false));
          yield put(kycFlowToggle(true));
          yield call(trackContactInfo);
          yield call(trackKycComplete);
          if (country !== 'DK') {
            yield call(riskAssessmentCaller);
          } else {
            yield call(shouldMonthioBeUsed);
          }
          break;
        case 'ERROR':
          yield call(cancelPayment);
          yield put(questionsViewToggle(false));
          break;
        default:
          throw new Error(
            `BUG: credit-question create needs to return either OK or ERROR as status. Got "${result.status}"`
          );
      }
    }
    if (useKalp && !useKyc) {
      yield put(setIsLoading(true));
      const result = yield call(
        creditAccountApi.kalpCreate,
        purchaseRequestToken,
        correlationId,
        country,
        kalpVersion,
        values
      );
      yield put(setIsLoading(false));
      switch (result.status) {
        case 'OK':
          yield put(questionsViewToggle(false));
          yield put(kalpFlowToggle(true));
          yield call(trackKalpComplete);
          yield call(riskAssessmentCaller);
          break;
        case 'ERROR':
          yield call(cancelPayment);
          yield put(questionsViewToggle(false));
          break;
        default:
          throw new Error(
            `BUG: credit-question create needs to return either OK or ERROR as status. Got "${result.status}"`
          );
      }
    }
  } catch (e) {
    yield put(setIsLoading(false));
    if (e instanceof UnexpectedServerResponse) {
      // console.error(e, e.response);

      yield put(prepareFailed());
      yield put(questionsViewToggle(false));
    } else {
      console.error(e);

      yield put(prepareFailed());
      yield put(questionsViewToggle(false));
    }
  }
}

function* shouldMonthioBeUsed() {
  const {
    purchaseRequestToken,
    correlationId,
    kycFlowDone,
    country,
    kycEnabled,
    kalpEnabled,
    kalpFlowDone
  } = yield select((state) => state.app);

  if (!kycEnabled && !kalpEnabled && country !== 'DK') {
    yield put(monthioCreateDone(false));
    return yield put(monthioFlowCompletedDone());
  }

  if (
    (!kycFlowDone || !kalpFlowDone) &&
    country !== 'DK' &&
    (kycEnabled || kalpEnabled)
  ) {
    yield put(monthioCreateDone(false));
    return yield call(kycKalpCheck);
  }
  if (kycFlowDone && kalpFlowDone && country !== 'DK') {
    yield put(monthioCreateDone(false));
    return yield put(monthioFlowCompletedDone());
  }

  yield put(setIsMonthioFlow());

  try {
    const {
      paymentMethods,
      selectedPaymentMethod,
      selectedPaymentMethodOption,
      authentication
    } = yield select((state) => state.app);

    const { status, response } = yield call(monthioCreate, {
      purchaseRequestToken,
      correlationId,
      ssn: authentication.ssn,
      downPayTime:
        paymentMethods[selectedPaymentMethod][selectedPaymentMethodOption]
          .downPayTime,
      monthlyCost:
        paymentMethods[selectedPaymentMethod][selectedPaymentMethodOption]
          .monthlyCost,
      totalCost:
        paymentMethods[selectedPaymentMethod][selectedPaymentMethodOption]
          .totalCost
    });

    if (status === 'OK') {
      const { redirectUrl } = response;

      yield put(setRedirectUrl(redirectUrl));
      return yield put(monthioCreateDone(true));
    }
    if (status === 'EXPIRED' || status === 'ERR') {
      return yield put(monthioCreateError({ type: 'ERROR', value: status }));
    }
    return yield;
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
    } else {
      console.error(e);
    }
    yield put(prepareFailed());
    return yield put(
      webcheckoutCompleteErrorEvent(`prePrepare: ${e.toString()}`)
    );
  }
}

export function* obtainMonthio(
  purchaseRequestToken,
  { timeoutDelay = 900000, pollDelay = 10000 } = {}
) {
  const { isMonthioFlow } = yield select((state) => state.app);

  if (!isMonthioFlow) {
    return;
  }

  try {
    const [status] = yield race([
      call(pollForMonthio, pollDelay, timeoutDelay),
      take(MONTHIO_CANCEL),
      delay(timeoutDelay)
    ]);

    if (status === monthioPollStatus.DONE_COMPLETED) {
      yield put(monthioFlowCompletedDone());
      return;
    }

    if (status === monthioPollStatus.DONE_ERROR) {
      yield put(monthioFlowCompletedError());
      return;
    }

    // No status received in case of poll timeout
    if (!status) {
      yield put(monthioFlowCompletedError());
    } else {
      yield put(monthioPollError());
    }
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
      yield put(monthioPollError());
    } else {
      throw e;
    }
  }
}

function* finalPoll() {
  /**
   * Wait a bit after window.closed
   */
  yield delay(500);

  const {
    pollMonthioOnce,
    pollMonthioOnceDone,
    monthioCompletedDone,
    purchaseRequestToken,
    correlationId
  } = yield select((state) => state.app);
  if (pollMonthioOnce && !pollMonthioOnceDone && !monthioCompletedDone) {
    yield put(monthioPollOnceDone());

    let status = null;
    const result = yield call(monthioPoll, purchaseRequestToken, correlationId);

    switch (result.status) {
      case 'DONE_COMPLETED':
        status = result.status;
        break;
      case 'DONE_ERROR':
        status = result.status;
        break;
      case 'EXPIRED':
        status = undefined;
        break;
      case 'ONGOING':
        status = undefined;
        break;
      default:
        throw new Error(
          `BUG: poll needs to return either DONE, EXPIRED or ONGOING. Got "${result.status}"`
        );
    }

    if (status === monthioPollStatus.DONE_COMPLETED) {
      yield put(setRedirectUrl(null));
      if (!monthioCompletedDone) {
        yield put(monthioFlowCompletedDone());
      }
    }

    if (status === monthioPollStatus.DONE_ERROR) {
      yield put(monthioFlowCompletedDone());
    }

    // No status received in case of poll timeout
    if (!status) {
      yield put(monthioPollError());
    } else {
      yield put(monthioPollError());
    }
    yield put(monthioPollOnceDone());
  }
  yield put(monthioPollOnceDone());
}

function* pollForMonthio(pollDelay, timeoutDelay) {
  const { correlationId, purchaseRequestToken } = yield select(
    (state) => state.app
  );
  const dateTimeoutDelay = Date.now() + timeoutDelay;
  while (true) {
    const { monthioCanceled, pollMonthioOnceDone, monthioCompletedDone } =
      yield select((state) => state.app);

    /** Wait some time to let finalPoll finish before continue
     * polling if still not finished after window closed.
     */
    if (pollMonthioOnceDone) {
      yield delay(1000);
    }

    /** Same case as EXPIRED but checking if real time has passed not just time
     * in application that may be paused with sleep mode or inactive browsers */
    if (
      Date.now() > dateTimeoutDelay ||
      monthioCanceled ||
      monthioCompletedDone
    ) {
      return undefined;
    }
    const result = yield call(monthioPoll, purchaseRequestToken, correlationId);

    switch (result.status) {
      case monthioPollStatus.DONE_COMPLETED:
        yield put(setRedirectUrl(null));
        if (!monthioCompletedDone) {
          yield put(monthioFlowCompletedDone());
        }
        return result.status;
      case monthioPollStatus.DONE_ERROR:
        if (!monthioCompletedDone) {
          yield put(monthioFlowCompletedDone());
        }
        return result.status;
      case monthioPollStatus.EXPIRED:
        return undefined;
      case monthioPollStatus.ONGOING:
        yield delay(pollDelay);
        break;
      default:
        throw new Error(
          `BUG: poll needs to return either DONE, EXPIRED or ONGOING. Got "${result.status}"`
        );
    }
  }
}

function* accountLookupAndRiskAssessment() {
  const { kycEnabled } = yield select((state) => state.app);
  if (!kycEnabled) {
    yield call(accountLookupCaller);
  }
  yield call(riskAssessmentCaller);
}

function* accountLookupCaller() {
  yield put(accountLookupStarted());
  const { authToken } = yield select((state) => state.app.authentication);
  const { purchaseRequestToken, correlationId } = yield select(
    (state) => state.app
  );

  const { downPayTime } = yield select(selectPaymentDetailsPartPayment);

  try {
    const result = yield call(
      accountLookup,
      purchaseRequestToken,
      authToken,
      downPayTime,
      correlationId
    );

    switch (result.status) {
      case accountLookupStatusType.NO_ACTION_NEEDED:
        yield call(trackAccountLookup);
        return yield put(
          accountLookupComplete(result.customerType, result.status, true)
        );
      case accountLookupStatusType.CREDIT_CHECK_NEEDED:
        yield call(trackAccountLookup);
        return yield put(
          accountLookupComplete(result.customerType, result.status, false)
        );
      case accountLookupStatusType.ORDER_AMOUNT_TOO_LOW:
        yield call(trackAccountLookup);
        return yield put(
          accountLookupComplete(result.customerType, result.status, true)
        );
      case accountLookupStatusType.CREDIT_CHECK_REJECTED:
        yield call(trackAccountLookup);
        return yield put(
          accountLookupComplete(result.customerType, result.status, true)
        );
      case accountLookupStatusType.GENERIC_DECLINE:
        yield call(trackAccountLookup);
        return yield put(
          accountLookupComplete(result.customerType, result.status, true)
        );
      case accountLookupStatusType.ACCOUNT_BLOCKED:
        yield call(trackAccountLookup);
        return yield put(
          accountLookupComplete(result.customerType, result.status, true)
        );

      case 'ERR':
        return yield put(
          accountLookupComplete(result.customerType, result.status, true)
        );
      default:
        throw new Error(
          `BUG: riskAssessment needs to return any accountLookupStatus as status. Got "${result.status}"`
        );
    }
  } catch (e) {
    yield put(prepareFailed());
    yield put(technicalError());
    return yield put(
      webcheckoutCompleteErrorEvent(`riskAssessment: ${e.toString()}`)
    );
  }
}

function* riskAssessmentCaller() {
  const { accountLookupStatus: status, isRiskAssessmentStarted } = yield select(
    (state) => state.partPayment
  );
  if (
    status !== accountLookupStatusType.CREDIT_CHECK_NEEDED ||
    isRiskAssessmentStarted
  ) {
    return yield;
  }

  yield put(riskAssessmentStarted());

  const { purchaseRequestToken, correlationId } = yield select(
    (state) => state.app
  );

  try {
    const result = yield call(
      riskAssessment,
      correlationId,
      purchaseRequestToken
    );

    switch (result.apiStatus) {
      case 'OK':
        yield call(trackCreditCheckResult, 'SUCCESS');
        return yield put(
          riskAssessmentComplete(
            result.status,
            result.financeableAmount,
            result.maximumLimit,
            result.approvedLimit,
            result.requiredLimit,
            result.offLimitAmountToPay,
            result.hasCreditLimitChanged,
            result.customer?.creditAccount?.creditLimit
          )
        );
      case 'ERR':
        yield call(trackCreditCheckResult, 'ERROR');
        return yield put(
          riskAssessmentComplete({ type: 'ERROR', value: result.value })
        );
      default:
        throw new Error(
          `BUG: riskAssessment needs to return either OK or ERR as status. Got "${result.status}"`
        );
    }
  } catch (e) {
    yield put(riskAssessmentFailed());
    yield put(technicalError());
    return yield put(
      webcheckoutCompleteErrorEvent(`riskAssessment: ${e.toString()}`)
    );
  }
}

function* initWebcheckout(action) {
  try {
    yield put({
      type: SET_PARENT_WINDOW_ORIGIN,
      parentWindowOrigin: action.parentWindowOrigin,
      preSelectedMonth: action.preSelectedMonth
    });

    yield addCustomCssToDocument(action.customCssUrl);

    yield call(initiatePurchaseRequest, {
      purchaseRequestToken: action.purchaseRequestToken
    });
  } catch (e) {
    console.error(e);
  }
}

function* retryConfig() {
  const { purchaseRequestToken } = yield select((state) => state.app);

  yield call(initiatePurchaseRequest, {
    purchaseRequestToken: purchaseRequestToken
  });
}

/**
 * Start authentication of user.
 *
 * Prepare parameters for the call to `obtainAuthToken` which
 * does the actual work of contacting the authsign service.
 */
function* startAuth() {
  yield put(authenticationEvent(EVENT_VALUE.OPENED));

  const {
    country,
    language,
    authentication: { ssn }
  } = yield select((state) => state.app);

  const method = { FI: 'ftn', SE: 'sbid', DK: 'mitid', NO: 'nbid' }[country];
  if (method === undefined) {
    throw new Error(
      `No authentication method defined for country code "${country}"`
    );
  }
  if (method === 'sbid') {
    yield call(obtainSbidAuthToken, language, method, ssn);
  } else {
    yield call(obtainAuthToken, language, method, ssn);
  }
}

/**
 * Return true if authentication started somewhere else
 * @param status
 * @returns true or false
 */
function* authAlreadyInProgress(status) {
  if (status && status === AUTHENTICATION_ALREADY_IN_PROGRESS) {
    yield put(authenticationEvent(EVENT_VALUE.ALREADY_IN_PROGRESS));
    yield put(authenticationAlreadyInProgress());
    return true;
  }
  return false;
}

function signAlreadyInProgress(status) {
  return status === SIGNING_ALREADY_IN_PROGRESS;
}

function signFailed(status) {
  return status === SIGNING_FAILED;
}

function* handleSignErrors(status) {
  if (signAlreadyInProgress(status)) {
    yield put(signingEvent(EVENT_VALUE.ALREADY_IN_PROGRESS));
    yield put(signingAlreadyInProgress());
  }
  if (signFailed(status)) {
    yield put(signingEvent(EVENT_VALUE.FAILED));
    yield put(signingFailed());
  }
}

export function* obtainSbidAuthToken(
  language,
  method,
  ssn,
  { timeoutDelay = 185000, pollDelay = 1000 } = {}
) {
  try {
    yield put(authenticationStarted());
    const { correlationId, purchaseRequestToken } = yield select(
      (state) => state.app
    );
    const response = {
      token: undefined,
      autoStartToken: undefined
    };

    if (method === 'sbid') {
      const { token, autoStartToken, status } = yield call(
        sbidStart,
        language,
        ssn,
        correlationId,
        purchaseRequestToken
      );
      response.pollingToken = token;
      response.autoStartToken = autoStartToken;
      response.status = status;
      yield put(authenticationSignAutoStartToken(autoStartToken, false));
    }

    if (yield call(authAlreadyInProgress, response.status)) {
      return;
    }

    const [authJwt, cancel] = yield race([
      call(
        pollForSbidAuthOrSignToken,
        sbidPoll,
        response.pollingToken,
        pollDelay,
        timeoutDelay
      ),
      take(CANCEL_SBID_AUTH_OR_SIGN),
      delay(timeoutDelay)
    ]);

    if (authJwt) {
      yield put(setAuthToken(authJwt));
      yield put(authenticationEvent(EVENT_VALUE.SUCCESSFUL));
      yield put(authenticationDone());
      yield call(trackAuthenticateComplete);
      return;
    }

    // We got no auth token. Either the user canceled or the
    // polling timed out.
    if (cancel) {
      yield put(authenticationEvent(EVENT_VALUE.CANCELED));
      yield put(authenticationCanceled());
    } else {
      yield put(authenticationEvent(EVENT_VALUE.FAILED));
      yield put(authenticationFailed());
    }
    // Send a cancel to the backend.  There's no response
    // we need to act on, so we spawn it in a separate 'thread'
    // and don't wait for the answer. No need to cancel if
    // auth request has been expired by auth service itself.
    if (cancel) {
      yield spawn(
        sbidCancel,
        response.pollingToken,
        correlationId,
        purchaseRequestToken
      );
    }
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
      yield put(authenticationEvent(EVENT_VALUE.FAILED));
      yield put(authenticationFailed());
    } else {
      throw e;
    }
  }
}

/**
 * Obtain authentication token from authsign service.
 *
 * Starts a new authentication request, opening a new window
 * for identification if needed, then polls the service for
 * a resulting token. When the poll returns the token, an
 * AUTHENTICATION_DONE event is dispatched, holding the
 * token as payload.
 *
 * A timeout, auth expired, user cancel or a thrown exception
 * will cause the procedure to abort.
 */
export function* obtainAuthToken(
  language,
  method,
  ssn,
  { timeoutDelay = 185000, pollDelay = 1000 } = {}
) {
  yield put(authenticationStarted());
  const { correlationId, purchaseRequestToken } = yield select(
    (state) => state.app
  );
  try {
    const { redirectUrl, pollingToken, status } = yield call(
      authApi.start,
      method,
      language,
      ssn,
      correlationId,
      purchaseRequestToken
    );

    if (yield call(authAlreadyInProgress, status)) {
      return;
    }

    // RedirectUrl is provided if the user should navigate
    // to it in order to identify.  Open it in a new window.
    if (redirectUrl) {
      yield put(setRedirectUrl(redirectUrl));
    }

    // eslint-disable-next-line no-unused-vars
    const [authToken, cancel, timeout] = yield race([
      call(pollForAuthToken, pollingToken, pollDelay, timeoutDelay),
      take(CANCEL_AUTHENTICATION),
      delay(timeoutDelay)
    ]);

    if (authToken) {
      yield put(setAuthToken(authToken));
      yield put(authenticationEvent(EVENT_VALUE.SUCCESSFUL));
      yield call(trackAuthenticateComplete);
      yield put(authenticationDone());
      return;
    }

    // We got no auth token. Either the user canceled or the
    // polling timed out.
    if (cancel) {
      yield put(authenticationEvent(EVENT_VALUE.CANCELED));
      yield put(authenticationCanceled());
    } else {
      yield put(authenticationEvent(EVENT_VALUE.FAILED));
      yield put(authenticationFailed());
    }
    // Send a cancel to the backend.  There's no response
    // we need to act on, so we spawn it in a separate 'thread'
    // and don't wait for the answer. No need to cancel if
    // auth request has been expired by auth service itself.
    if (cancel || timeout) {
      yield spawn(
        authApi.cancel,
        pollingToken,
        correlationId,
        purchaseRequestToken
      );
    }
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
      yield put(authenticationEvent(EVENT_VALUE.FAILED));
      yield put(authenticationFailed());
    } else {
      throw e;
    }
  }
}

function* pollForSbidAuthOrSignToken(
  pollMethod,
  pollingToken,
  pollDelay,
  timeoutDelay
) {
  const { correlationId, purchaseRequestToken } = yield select(
    (state) => state.app
  );
  const dateTimeoutDelay = Date.now() + timeoutDelay;
  while (true) {
    /** Same case as EXPIRED but checking if real time has passed not just time
     * in application that may be paused with sleep mode or inactive browsers */
    if (Date.now() > dateTimeoutDelay) {
      return undefined;
    }

    const result = yield call(
      pollMethod,
      pollingToken,
      correlationId,
      purchaseRequestToken
    );
    switch (result.status) {
      case sbidPollStatus.DONE:
        return result.authJwt || result.signJwt;
      case sbidPollStatus.EXPIRED:
        return undefined;
      case sbidPollStatus.ONGOING:
        yield put(authenticationSignQR(result.qrData));
        yield delay(pollDelay);
        break;
      default:
        throw new Error(
          `BUG: poll needs to return either DONE, PENDING, EXPIRED or ONGOING. Got "${result.status}"`
        );
    }
  }
}

function* pollForAuthToken(pollingToken, pollDelay, timeoutDelay) {
  const { correlationId, purchaseRequestToken } = yield select(
    (state) => state.app
  );
  const dateTimeoutDelay = Date.now() + timeoutDelay;
  while (true) {
    /** Same case as EXPIRED but checking if real time has passed not just time
     * in application that may be paused with sleep mode or inactive browsers */
    if (Date.now() > dateTimeoutDelay) {
      return undefined;
    }

    const result = yield call(
      authApi.poll,
      pollingToken,
      correlationId,
      purchaseRequestToken
    );
    switch (result.status) {
      case 'DONE':
        return result.authToken;
      case 'EXPIRED':
        return undefined;
      case 'ONGOING':
        yield delay(pollDelay);
        break;
      default:
        throw new Error(
          `BUG: poll needs to return either DONE, EXPIRED or ONGOING. Got "${result.status}"`
        );
    }
  }
}

/**
 * Start signing of user.
 *
 * Prepare parameters for the call to `obtainSignToken` which
 * does the actual work of contacting the authsign service.
 */
function* startSigning() {
  yield put(signingEvent(EVENT_VALUE.OPENED));

  const { purchaseRequestToken, country } = yield select((state) => state.app);

  const method = { FI: 'ftn', SE: 'sbid', DK: 'mitid', NO: 'nbid' }[country];
  if (method === undefined) {
    throw new Error(`No signing method defined for country code "${country}"`);
  }

  if (method === 'sbid') {
    yield call(obtainSbidSignToken, purchaseRequestToken);
  } else {
    yield call(obtainSignToken, purchaseRequestToken);
  }
}

export function* obtainSbidSignToken(
  purchaseRequestToken,
  { timeoutDelay = 185000, pollDelay = 1000 } = {}
) {
  yield put(signingStarted());
  const { correlationId } = yield select((state) => state.app);
  try {
    const { token, autoStartToken, status } = yield call(
      sbidSignStart,
      purchaseRequestToken,
      correlationId
    );

    yield put(authenticationSignAutoStartToken(autoStartToken, true));

    if (signAlreadyInProgress(status) || signFailed(status)) {
      yield call(handleSignErrors, status);
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const [signJwt, cancel] = yield race([
      call(
        pollForSbidAuthOrSignToken,
        sbidSignPoll,
        token,
        pollDelay,
        timeoutDelay
      ),
      take(CANCEL_SBID_AUTH_OR_SIGN),
      delay(timeoutDelay)
    ]);

    if (signJwt) {
      yield put(signingEvent(EVENT_VALUE.SUCCESSFUL));
      yield put(signingDone(signJwt));
      yield call(trackSign, { type: 'SIGN' });
      return;
    }

    // We got no sign token. Either the user canceled or the
    // polling timed out.
    if (cancel) {
      yield put(signingEvent(EVENT_VALUE.CANCELED));
      yield put(signingCanceled());
    } else {
      yield put(signingEvent(EVENT_VALUE.FAILED));
      yield put(signingFailed());
    }
    // Send a cancel to the backend.  There's no response
    // we need to act on, so we spawn it in a separate 'thread'
    // and don't wait for the answer. No need to cancel if
    // sign request has been expired by sign service itself.
    if (cancel) {
      yield spawn(sbidSignCancel, token, correlationId, purchaseRequestToken);
    }
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
      yield put(signingEvent(EVENT_VALUE.FAILED));
      yield put(signingTechnicalError());
    } else {
      throw e;
    }
  }
}

export function* obtainSignToken(
  purchaseRequestToken,
  { timeoutDelay = 185000, pollDelay = 1000 } = {}
) {
  yield put(signingStarted());
  const { correlationId } = yield select((state) => state.app);
  try {
    const { redirectUrl, pollingToken, status } = yield call(
      signApi.start,
      purchaseRequestToken,
      correlationId
    );
    if (redirectUrl) {
      yield put(setRedirectUrl(redirectUrl));
    }

    if (signAlreadyInProgress(status) || signFailed(status)) {
      yield call(handleSignErrors, status);
      return;
    }

    // RedirectUrl is provided if the user should navigate
    // to it in order to identify.  Open it in a new window.
    if (redirectUrl) {
      yield put(setRedirectUrl(redirectUrl));
    }

    // eslint-disable-next-line no-unused-vars
    const [signToken, cancel, timeout] = yield race([
      call(pollForSignToken, pollingToken, pollDelay, timeoutDelay),
      take(CANCEL_SIGNING),
      delay(timeoutDelay)
    ]);

    if (signToken) {
      yield put(signingEvent(EVENT_VALUE.SUCCESSFUL));
      yield put(signingDone(signToken));
      yield call(trackSign, { type: 'SIGN' });
      return;
    }

    // We got no sign token. Either the user canceled or the
    // polling timed out.
    if (cancel) {
      yield put(signingEvent(EVENT_VALUE.CANCELED));
      yield put(signingCanceled());
    } else {
      yield put(signingEvent(EVENT_VALUE.FAILED));
      yield put(signingFailed());
    }
    // Send a cancel to the backend.  There's no response
    // we need to act on, so we spawn it in a separate 'thread'
    // and don't wait for the answer. No need to cancel if
    // sign request has been expired by sign service itself.
    if (cancel || timeout) {
      yield spawn(
        signApi.cancel,
        pollingToken,
        correlationId,
        purchaseRequestToken
      );
    }
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
      yield put(signingEvent(EVENT_VALUE.FAILED));
      yield put(signingTechnicalError());
    } else {
      throw e;
    }
  }
}

function* pollForSignToken(pollingToken, pollDelay, timeoutDelay) {
  const { correlationId, purchaseRequestToken } = yield select(
    (state) => state.app
  );
  const dateTimeoutDelay = Date.now() + timeoutDelay;
  while (true) {
    /** Same case as EXPIRED but checking if real time has passed not just time
     * in application that may be paused with sleep mode or inactive browsers */
    if (Date.now() > dateTimeoutDelay) {
      return undefined;
    }
    const result = yield call(
      signApi.poll,
      pollingToken,
      correlationId,
      purchaseRequestToken
    );
    switch (result.status) {
      case 'DONE':
        return result.signToken;
      case 'EXPIRED':
        return undefined;
      case 'ONGOING':
        yield delay(pollDelay);
        break;
      default:
        throw new Error(
          `BUG: poll needs to return either DONE, EXPIRED or ONGOING. Got "${result.status}"`
        );
    }
  }
}

function* collectUserInfo() {
  const { purchaseRequestToken, correlationId, email, mobile } = yield select(
    (state) => state.app
  );

  const result = yield call(
    creditAccountApi.customerContactInfo,
    purchaseRequestToken,
    correlationId,
    email,
    mobile
  );

  switch (result.status) {
    case 'OK':
      yield put(userInfoCollectionComplete());
      return result.status;
    case 'VALIDATION_ERR':
      yield put(userInfoCollectionEApiError(result));
      return undefined;
    case 'ERR':
      return undefined;
    default:
      throw new Error(
        `BUG: customerContactInfo needs to return either OK, VALIDATION_ERR or ERR. Got "${result.status}"`
      );
  }
}

function* authorize({ signToken } = {}) {
  const { purchaseRequestToken, correlationId } = yield select(
    (state) => state.app
  );

  yield put(authorizeStarted());

  try {
    const result = yield call(
      creditAccountApi.authorize,
      purchaseRequestToken,
      correlationId,
      signToken
    );

    switch (result.status) {
      case 'OK':
        yield put(authorizeComplete(result?.value?.customer));
        yield put(paymentProcessingEvent(EVENT_VALUE.SUCCESSFUL));
        yield call(trackClickedConfirmPurchase, true);
        yield call(trackPurchaseComplete);

        break;
      case 'UNAUTHORIZED':
        yield put(authorizeIsUnauthorized());
        yield put(paymentProcessingEvent(EVENT_VALUE.UNAUTHORIZED));
        yield call(trackClickedConfirmPurchase, false);
        break;
      case 'ERR':
        yield put(authorizeFailed());
        yield put(paymentProcessingEvent(EVENT_VALUE.FAILED));
        yield put(
          webcheckoutCompleteErrorEvent(`authorize: ${result.value.toString()}`)
        );
        yield call(trackClickedConfirmPurchase, false);
        break;
      default:
        throw new Error(
          `BUG: authorize needs to return either OK or ERR as status. Got "${result.status}"`
        );
    }
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
    } else {
      console.error(e);
    }
    yield put(authorizeFailed());
    yield put(paymentProcessingEvent(EVENT_VALUE.FAILED));
    yield put(webcheckoutCompleteErrorEvent(`authorize: ${e.toString()}`));
  }
}

function postMessageEvent(action) {
  postMessageOut(action.webcheckoutEvent);
}

function* setSelectedPaymentMethod(action) {
  if (action.paymentMethod !== null) {
    yield put(chosenPaymentMethodEvent(action.paymentMethod));
  }
}

function* cancelPayment() {
  const { purchaseRequestToken, correlationId } = yield select(
    (state) => state.app
  );

  try {
    yield call(creditAccountApi.abort, purchaseRequestToken, correlationId);
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
    } else {
      console.error(e);
    }
  } finally {
    yield put({ type: SHOW_CANCEL_VIEW, value: true });
    yield put(webcheckoutCompleteCancelEvent());
    yield call(trackCancel);
  }
}

function* cancelAtEmailAndPhoneCollection() {
  const { purchaseRequestToken, correlationId } = yield select(
    (state) => state.app
  );

  try {
    yield call(creditAccountApi.abort, purchaseRequestToken, correlationId);
  } catch (e) {
    if (e instanceof UnexpectedServerResponse) {
      console.error(e, e.response);
    } else {
      console.error(e);
    }
  } finally {
    yield put({ type: USER_INFO_COLLECTION_CANCELLED_CLICKED });
    yield put(emailAndPhoneRedirectClicked());
    yield call(trackCancel);
  }
}

export function* mySaga() {
  yield takeLeading(AUTHORIZE, authorize);
  yield takeLeading(PREPARE, shouldMonthioBeUsed);
  yield takeLeading(START, initWebcheckout);
  yield takeLeading(CONFIG_RETRY, retryConfig);
  yield takeLeading(START_AUTH, startAuth);
  yield takeLeading(START_SIGN, startSigning);
  yield takeEvery(WEBCHECKOUT_EVENT, postMessageEvent);
  yield takeEvery(SET_SELECTED_PAYMENT_METHOD, setSelectedPaymentMethod);
  yield takeEvery(CANCEL_PAYMENT, cancelPayment);
  yield takeLeading(AUTHENTICATION_DONE, checkKycKalpEnabled);
  yield takeLeading(
    MONTHIO_FLOW_COMPLETED_DONE,
    accountLookupAndRiskAssessment
  );
  yield takeLeading(MONTHIO_POLL_ONCE, finalPoll);
  yield takeLeading(MONTHIO_CREATE_DONE, obtainMonthio);
  yield takeLeading(MONTHIO_OPEN_AGAIN, obtainMonthio);
  yield takeLeading(SIGNING_DONE, authorize);
  yield takeLeading(USER_INFO_COLLECTION_CONTINUE_CLICKED, collectUserInfo);
  yield takeLeading(
    USER_INFO_COLLECTION_CANCELLED_CLICKED,
    cancelAtEmailAndPhoneCollection
  );
  yield takeEvery(QUESTIONS_POST, postKycKalpQuestions);
  yield takeLeading(QUESTIONS_CHECK, kycKalpCheck);
  yield takeEvery(OPEN_WINDOW, ({ url }) => window.open(url, '_blank'));
}
