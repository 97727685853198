import { select, call } from 'redux-saga/effects';

function* trackEvent(name = '', customAttributes = {}, subevent = '') {
  const { storeName, isLockedMonth, merchantId } = yield select(
    (state) => state.app
  );

  if (window.dataLayer) {
    window.dataLayer.push({
      event: name,
      ...customAttributes,
      merchant: merchantId,
      storeName,
      isTeleSales: isLockedMonth && !storeName,
      subevent
    });
  }
}

export function* trackGenesisInitiate(customAttributes = {}) {
  yield call(trackEvent, 'initiate', customAttributes, 'initiate_complete');
}

export function* trackAuthenticateComplete(customAttributes = {}) {
  yield call(
    trackEvent,
    'genesisAuthenticate',
    customAttributes,
    'authenticate_complete'
  );
}

export function* trackAccountLookup(customAttributes = {}) {
  yield call(
    trackEvent,
    'genesisAccountLookUp',
    customAttributes,
    'accountLookUp_complete'
  );
}

export function* trackContactInfo(customAttributes = {}) {
  yield call(
    trackEvent,
    'genesisContactInfo',
    customAttributes,
    'contactInfo_complete'
  );
}

export function* trackKycComplete(customAttributes = {}) {
  yield call(trackEvent, 'genesisKyc', customAttributes, 'kyc_complete');
}

export function* trackKalpComplete(customAttributes = {}) {
  yield call(
    trackEvent,
    'genesisCreditAffordability',
    customAttributes,
    'creditAffordability_complete'
  );
}

export function* trackPurchaseComplete(customAttributes = {}) {
  yield call(
    trackEvent,
    'genesisAuthorise',
    customAttributes,
    'authorise_complete'
  );
}

export function* trackStart(customAttributes = {}) {
  yield call(trackEvent, 'start', customAttributes);
}

export function* trackError(customAttributes = {}) {
  yield call(trackEvent, 'error', customAttributes);
}

export function* trackCancel(customAttributes = {}) {
  yield call(trackEvent, 'cancel', customAttributes);
}

export function* trackContinue(customAttributes = {}) {
  yield call(trackEvent, 'continue', customAttributes);
}

export function* trackSign(customAttributes = {}) {
  yield call(trackEvent, 'sign', customAttributes);
}

export function* trackClickedConfirmPurchase(isSuccesful) {
  yield call(trackEvent, 'clicked-confirm-purchase', { success: isSuccesful });
}

export function* trackCreditCheckResult(creditCheckResultType) {
  yield call(trackEvent, 'credit-check', { creditCheckResultType });
}
