import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from '@teliads/components/foundations';
import { UserInformationComponent } from '@tf-b2c/user-information-component';
import PaymentMethod from './PaymentMethod';
import ProcessingPurchase from './ProcessingPurchase';
import CancelView from './CancelView';
import PartPaymentNotPermittedView from './PartPaymentNotPermittedView';
import MoreInformation from './MoreInformation';
import PaymentMethodIcon from './PaymentMethodIcon';
import ContactSupport from './ContactSupport';
import { PAYMENT_METHOD_PART_PAYMENT } from '../constants';
import {
  selectCanChangePaymentMethod,
  selectCreditGranted,
  selectCreditGrantedForPayTheDifference
} from '../selectors/selectors';
import Authenticating from './Authenticating';
import Signing from './Signing';
import ROUTES from '../constants/routes';
import PoweredByTeliaFinance from './PoweredByTeliaFinance';
import FLOWS from '../constants/flows';
import SdkView from './SdkView';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';
import {
  userInfoCollectionStart,
  // userCreditCheckQuestionsPost,
  questionsPost,
  cancelPayment,
  questionsViewToggle,
  userInfoCollectionContinueClicked,
  userInfoCollectionEmail,
  userInfoCollectionPhone
} from '../actions/applicationActions';
import UserInfoCollectionView from './UserInfoCollection/UserInfoCollectionView';
import Monthio from './Monthio/Monthio';
import accountLookupStatusType from '../constants/accountLookupStatusType';
import Loading from './Loading';

const PartPayment = ({ onClick, history }) => {
  const {
    selectedPaymentMethodOption: selectedOption,
    email,
    mobile,
    showProcessingPurchaseRequest,
    showCancelView,
    processPurchaseRequestSuccess,
    isAuthenticating,
    isMonthioCreateDone,
    isSigning,
    monthioCompletedDone,
    flow,
    errorContactSupport,
    partPaymentMinimumAmount,
    country,
    merchantId,
    isLockedMonth,
    storeName,
    selectedPaymentMethod,
    paymentMethods,
    emailPhoneCollectionEnabled,
    userInfoCollectionViewIsActive,
    kalpEnabled,
    kalpFlowDone,
    kycQuestionsViewIsActive,
    kycEnabled,
    kycQuestions,
    questionsToDisplay,
    kycFlowDone,
    language,
    isLoading
  } = useSelector((state) => state.app);
  const { accountLookupStatus, customerType } = useSelector(
    (state) => state.partPayment
  );
  const { account: options } = useSelector((state) => state.app.paymentMethods);

  const { showMoreInformation, isPrepareComplete } = useSelector(
    (state) => state.partPayment
  );
  const kycLanguage = useMemo(() => {
    if (country !== 'SE' && language === 'en') {
      return `${language}-${country}`;
    }
    return language;
  }, [country, language]);
  const canChangePaymentMethod = useSelector(selectCanChangePaymentMethod);

  // An overlay component can be rendered instead of the payment method content
  const [overlayComponent, setOverlayComponent] = useState(null);

  const { t } = useTranslation();
  const { merchantTranslation } = useMerchantSpecificTranslationKey();
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectedChanged, setIsSelectedChanged] = useState(0);
  const dispatch = useDispatch();
  const creditGranted = useSelector(selectCreditGranted);
  const creditGrantedForPayTheDifference = useSelector(
    selectCreditGrantedForPayTheDifference
  );

  useEffect(() => {
    if (
      emailPhoneCollectionEnabled &&
      !kycEnabled &&
      (creditGranted || creditGrantedForPayTheDifference)
    ) {
      dispatch(userInfoCollectionStart());
    }
  }, [
    emailPhoneCollectionEnabled,
    creditGranted,
    creditGrantedForPayTheDifference
  ]);

  useEffect(() => {
    const cardPaymentIsEnabled = Object.hasOwn(paymentMethods, 'cardPayment');
    if (
      !cardPaymentIsEnabled ||
      (cardPaymentIsEnabled && isSelectedChanged !== 0)
    ) {
      setIsSelected(selectedPaymentMethod === 'account');
    }
    setIsSelectedChanged(isSelectedChanged + 1);
  }, [selectedPaymentMethod]);

  const partPaymentTitle =
    flow === FLOWS.WEB
      ? merchantTranslation('payment_method.pay_in_parts')
      : t('thank_you_pay_in_parts.pay_in_parts');

  // Used in DK only
  let totalCostPartPayment;
  if (
    Object.hasOwn(paymentMethods, selectedPaymentMethod) &&
    paymentMethods[selectedPaymentMethod].length > 0
  ) {
    totalCostPartPayment =
      paymentMethods[selectedPaymentMethod][selectedOption].totalCost;
  }

  useEffect(() => {
    if (showProcessingPurchaseRequest) {
      setOverlayComponent(<ProcessingPurchase />);
    } else if (showMoreInformation) {
      setOverlayComponent(<MoreInformation />);
    } else if (isAuthenticating) {
      setOverlayComponent(<Authenticating />);
    } else if (isMonthioCreateDone && !monthioCompletedDone) {
      setOverlayComponent(<Monthio />);
    } else if (isSigning) {
      setOverlayComponent(<Signing />);
    } else if (isLoading) {
      setOverlayComponent(
        <>
          <LoadingContainer data-cy="PartPaymentOverlayLoading">
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
            <PoweredByTeliaFinance />
          </LoadingContainer>
        </>
      );
    } else if (kycQuestionsViewIsActive) {
      setOverlayComponent(
        <>
          <UserInformationComponent
            userData={{ email, phone: mobile }}
            kycEnabled={kycEnabled && !kycFlowDone}
            kalpEnabled={kalpEnabled && !kalpFlowDone}
            questions={questionsToDisplay}
            trackKyc={(questionText, subevent) => {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: 'genesisKyc',
                  merchant: merchantId,
                  storeName,
                  isTeleSales: isLockedMonth && !storeName,
                  subevent: `kyc_question_${subevent}`
                });
              }
            }}
            trackKalp={(questionText, subevent) => {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: 'genesisCreditAffordability',
                  merchant: merchantId,
                  storeName,
                  isTeleSales: isLockedMonth && !storeName,
                  subevent: `creditAffordability_question_${subevent}`
                });
              }
            }}
            callback={(values) => {
              if (emailPhoneCollectionEnabled) {
                dispatch(
                  userInfoCollectionEmail(values.contactInformationEmail)
                );
                dispatch(
                  userInfoCollectionPhone(values.contactInformationTelephone)
                );
                dispatch(userInfoCollectionContinueClicked());
              }
              dispatch(questionsPost(values));
            }}
            language={kycLanguage}
            backToPaymentMethods={() => {
              dispatch(cancelPayment());
              dispatch(questionsViewToggle(false));
            }}
          />
          <PoweredByTeliaFinance />
        </>
      );
    } else if (
      emailPhoneCollectionEnabled &&
      userInfoCollectionViewIsActive &&
      (customerType === 'NEW' || customerType === 'PAY_THE_DIFFERENCE_NEW')
    ) {
      setOverlayComponent(<UserInfoCollectionView />);
    } else if (showCancelView) {
      setOverlayComponent(<CancelView />);
    } else if (
      accountLookupStatus === accountLookupStatusType.ORDER_AMOUNT_TOO_LOW ||
      (country === 'DK' &&
        totalCostPartPayment &&
        totalCostPartPayment < partPaymentMinimumAmount)
    ) {
      setOverlayComponent(
        <PartPaymentNotPermittedView
          currency={options[selectedOption].currency}
        />
      );
    } else if (errorContactSupport) {
      setOverlayComponent(
        <>
          <ContactSupport />
          <PoweredByTeliaFinance />
        </>
      );
    } else {
      setOverlayComponent(null);
    }
  }, [
    isLoading,
    showProcessingPurchaseRequest,
    showMoreInformation,
    isAuthenticating,
    isSigning,
    isMonthioCreateDone,
    monthioCompletedDone,
    showCancelView,
    errorContactSupport,
    accountLookupStatus,
    userInfoCollectionViewIsActive,
    kycFlowDone,
    kalpFlowDone,
    kalpEnabled,
    kycQuestionsViewIsActive,
    kycEnabled,
    kycQuestions,
    kycFlowDone,
    emailPhoneCollectionEnabled
  ]);

  useEffect(() => {
    if (processPurchaseRequestSuccess) {
      history.push(ROUTES.THANK_YOU_PART_PAYMENT);
    }
  }, [processPurchaseRequestSuccess]);

  const onClickCallback = () => {
    if (isSelectedChanged !== 0) {
      setIsSelected(selectedPaymentMethod === 'account');
      onClick(PAYMENT_METHOD_PART_PAYMENT);
    }
  };
  return (
    <PaymentMethod
      name={partPaymentTitle}
      isSelected={isSelected}
      onClick={onClickCallback}
      canChangePaymentMethod={canChangePaymentMethod}
      icon={<PaymentMethodIcon paymentMethod={PAYMENT_METHOD_PART_PAYMENT} />}
      overlayComponent={overlayComponent}
      dataCy="PartPayment"
    >
      <SdkView isPrepareComplete={isPrepareComplete} />
      <PoweredByContainer>
        <PoweredByTeliaFinance />
      </PoweredByContainer>
    </PaymentMethod>
  );
};

PartPayment.propTypes = {
  onClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const PoweredByContainer = styled.div`
  padding: ${spacing.spacing0} ${spacing.spacing0} ${spacing.spacing16}
    ${spacing.spacing0};
`;

const LoadingWrapper = styled.div`
  width: 50px;
  min-height: 487px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default withRouter(PartPayment);
