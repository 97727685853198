import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  SecciAndTermsConditionLink,
  TermsAndConditions
} from '../styled-components/styledcomponents';
import MerchantLink from './MerchantLink';
import useMerchantSpecificTranslationKey from '../hooks/useMerchantSpecificTranslationKey';
import useMerchantNameFromId from '../hooks/useMerchantNameFromId';
import usePhraseFormattedKeyFromMerchantId from '../hooks/usePhraseFormattedKeyFromMerchantId';

const TextSummaryOfAgreements = () => {
  const { merchantId } = useSelector((state) => state.app);

  const { t } = useTranslation();
  const { merchantTranslationKey } = useMerchantSpecificTranslationKey();
  const merchantName = useMerchantNameFromId(merchantId);
  const linkKey = 'pay_in_parts.terms_and_conditions_secci_text_link';
  const merchantLink = usePhraseFormattedKeyFromMerchantId(linkKey, merchantId);

  return (
    <TermsAndConditions>
      <Trans
        i18nKey={merchantTranslationKey(
          'pay_in_parts.terms_and_conditions_secci_text'
        )}
        components={{
          link0: (
            <SecciAndTermsConditionLink
              href={t('pay_in_parts.terms_and_conditions_secci_text_link_0')}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="TermsAndConditions"
            >
              Terms and Conditions
            </SecciAndTermsConditionLink>
          ),
          link1: (
            <SecciAndTermsConditionLink
              href={t('pay_in_parts.terms_and_conditions_secci_text_link_1')}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="SecciLinkSummaryView"
            >
              SECCI
            </SecciAndTermsConditionLink>
          ),
          link2: (
            <SecciAndTermsConditionLink
              href={t('pay_in_parts.terms_and_conditions_secci_text_link_2')}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="IntegrityPolicy"
            >
              Privacy policy
            </SecciAndTermsConditionLink>
          ),
          merchantLink: (
            <MerchantLink merchantLink={merchantLink}>
              Merchant Term
            </MerchantLink>
          ),
          merchantName
        }}
      />
    </TermsAndConditions>
  );
};

export default TextSummaryOfAgreements;
